import React from 'react'
import Link from 'next/link'

const navRoutes: {
	href: string
	label: string
}[] = [
	{
		label: 'Dashboard',
		href: '/',
	},
]

const Header = () => {
	return (
		<header className="bg-gray-900 row-all col-all px-5 py-2">
			<div className="flex items-center">
				<div className="text-gray-200">
					<span className="text-xl">Wallet Export</span>
					<br />
					<span className="text-sm">Onchain accounting made easy</span>
				</div>
				<ul className="flex align-middle">
					{navRoutes.map(({ href, label }) => {
						return (
							<li key={href} className="px-5">
								<Link href={href}>
									<a className="text-gray-200">{label}</a>
								</Link>
							</li>
						)
					})}
				</ul>
			</div>
		</header>
	)
}

export default Header
