import { useEffect } from 'react'
import ReactGA from 'react-ga'

const NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID =
	process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID

const initGoogleAnalytics = () => {
	ReactGA.initialize(NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID)
}

const logPageView = () => {
	ReactGA.set({ page: window.location.pathname })
	ReactGA.pageview(window.location.pathname)
}

const useGoogleAnalytics = () => {
	useEffect(() => {
		if (!window['GA_INITIALIZED']) {
			initGoogleAnalytics()
			window['GA_INITIALIZED'] = true
		}
		logPageView()
	}, [])
}

export default useGoogleAnalytics
