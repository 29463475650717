import React from 'react'

import Layout from 'components/Layout/Layout'
import { DefaultSEO } from 'components/SEO'

import useGoogleAnalytics from 'hooks/useGoogleAnalytics'

import '../styles/tailwind.css'

const App = ({ Component, pageProps }: CustomAppProps) => {
	useGoogleAnalytics()

	return (
		<Layout>
			<DefaultSEO />
			<Component {...pageProps} />
		</Layout>
	)
}

export interface CustomAppProps {
	Component: any
	pageProps: any
	err?: any
}

export default App
