import React from 'react'

import Header from './Header'
import Sidebar from './Sidebar'
// import Footer from './Footer'

const DefaultLayout = ({ children }: Props) => {
	return (
		<>
			<div className="grid grid-rows-app grid-cols-app min-h-screen">
				<Header />
				<Sidebar />
				<main className="row-content col-content bg-gray-200 p-5 overflow-x-scroll">
					{children}
				</main>
				{/* <Footer /> */}
			</div>
		</>
	)
}

interface Props {
	children: React.ReactNode
}

export default DefaultLayout
