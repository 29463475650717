import React from 'react'
import { NextSeo, DefaultSeo as DefaultNextSeo, NextSeoProps } from 'next-seo'

const defaultSEOProps: NextSeoProps = {
	title: 'WalletExport - Export cryptocurrency transactions',
	description: 'Streamline blockchain transactions for analysis and accounting purposes 💱📈',
}

export const DefaultSEO = () => {
	return <DefaultNextSeo {...defaultSEOProps} />
}

const SEO = (props: NextSeoProps) => {
	return <NextSeo {...props} />
}

export default SEO
